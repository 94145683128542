<!-- 振动分析报警 -->
<template>
    <div class="page_container ">
        <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40">
            <div class="pdl20">振动分析报警</div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30">
            <div class="flex pdt_20 fn_size16">
                <div class="flex divMargin align_center">
                    <div class="spanWidth">机器名称:</div>
                    <el-input v-model="mIDValue" style="width: 180px;" placeholder="请输入"></el-input>
                    <!-- <el-button type="primary" class="width_110 mg_left_10" style=" background: #009BFD;border: none;"
                        @click="queryTest">查询
                    </el-button> -->
                </div>
                <div class="flex divMargin align_center">
                    <div class="mg_left_20">创建时间：</div>
                    <el-date-picker v-model="timeValue" type="datetimerange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </div>
                <div class="flex justify_between" style="flex:1">
                    <el-button type="primary" class="width_110 mg_left_10" style=" background: #009BFD;border: none;"
                        @click="query">查询
                    </el-button>
                     
                    <div>
                        <!-- <el-button type="primary" class="width_180 mg_left_10" style=" background: #009BFD;border: none;"
                            @click='linkMaintain()' v-if="this.machineLearning">预测性维护
                        </el-button> -->
                         <el-button type="primary" class="mg_left_10" @click="exportRepairRecord"
                            style=" background: #009BFD;border: none;">导出</el-button>
                        <el-button type="primary" class="width_180 mg_left_10" style=" background: #009BFD;border: none;"
                            @click='linkTo()'>振动分析
                        </el-button>
                    </div>
                    
                </div>

            <!-- <div class="flex align_center">
                    <div class="spanWidth">结束时间：</div>
                    <div>
                        <el-input v-model="input" style="width: 150px;"></el-input>
                    </div>
                </div> -->
        </div>
        <div class="flex justify_between pdt_20 fn_size16">
            <!-- <el-button type="primary" class="width_110" style=" background: #009BFD;border: none;">导出
                </el-button> -->
        </div>
        <!-- table -->
        <div>
            <el-table ref="multipleTable" :data="listData" tooltip-effect="dark" style="width: 100%" class="mg_top_20"
                 @selection-change="handleSelectionChange" height='588'
                :default-sort="{prop: 'date', order: 'descending'}"
                 @row-dblclick="dblclickGotoFaultDetails" border=""  id="repairPlanTable">
                <el-table-column type="index" label="序号" width="55"></el-table-column>
                <el-table-column prop="projectID" label="项目名称" width="" show-overflow-tooltip></el-table-column>
                <el-table-column prop="machineID" label="机器名称" width=""></el-table-column>
                <el-table-column prop="sensorID" label="测点编号" width=""></el-table-column>
                <el-table-column prop="alarmStatus" label="报警状态数值" width="">
                    <template slot-scope="scope">
                    {{scope.row.alarmStatus | istatus}}
                    </template>
                </el-table-column>
<!-- <el-table-column prop="alarmStatus" label="报警状态数值" width="">
                    <template slot-scope="scope">
     <el-tag type="info" v-if="scope.row.alarmStatus == 2">未处理</el-tag>
     <el-tag v-if="scope.row.alarmStatus == 1">已处理</el-tag>
            </template> 
                    </el-table-column> -->
                <el-table-column prop="sensor" label="测点名称"></el-table-column>
                <el-table-column prop="alarmRank" label="报警状态" width="">


                </el-table-column>
                <el-table-column prop="gmtCreate" label="创建时间" :formatter="formatDate" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="procStatus" label="报警处理状态" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="gmtProc" label="处理时间" :formatter="formatDate" show-overflow-tooltip></el-table-column>
                <el-table-column  label="处理结果" >
 <template slot-scope="scope">
     <el-tag type="info" v-if="scope.row.procResult == 0">未处理</el-tag>
     <el-tag v-if="scope.row.procResult == 1">已处理</el-tag>
 </template>

                </el-table-column>
                <!-- <el-table-column prop="procResult" label="处理结果" :formatter="formatResult">              
                </el-table-column> -->
                <el-table-column prop="procDetail" label="客户报警处理相关信息" show-overflow-tooltip></el-table-column>
                <!-- <el-table-column prop="procDetailImgs" label="处理详情"></el-table-column> -->
                <!-- <el-table-column prop="algorithmFactorDtos" label="算法指标原因明细"  show-overflow-tooltip></el-table-column> -->
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                            <span style="border-bottom: 1px solid #009BFD;" @click="check(scope.row)">详情</span>
                        </el-button>

                    </template>
                </el-table-column>
            </el-table>
            <div class="flex justify_end">
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
        <!-- 选择框 -->
        <!-- <div class="bgc_E2F0FE flex align_end  height_64">
                <div class="tab_box mg_left_30 " :class="tabIndex==0?'tab_box_active':''" @click="handleChange(0)">领用明细</div>
            </div>
            
            <div class="mg_top_22">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20" @selection-change="handleSelectionChange" height="242"
                    :default-sort="{prop: 'date', order: 'descending'}">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="" label="工单编号" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="" label="备件编号" width="" ></el-table-column>
                    <el-table-column prop="" label="备件名称" width=""></el-table-column>
                    <el-table-column prop="" label="备件型号" width=""></el-table-column>
                    <el-table-column prop="" label="单位" width="" ></el-table-column>
                    <el-table-column prop="" label="申请数量" ></el-table-column>
                    <el-table-column prop="" label="单价"></el-table-column>
                    <el-table-column prop="" label="申请总价"></el-table-column>
                    <el-table-column prop="" label="备注"></el-table-column>
                </el-table>
                <div class="flex justify_end">
                    <el-pagination layout="prev, pager, next" :total="pageQuantity1*pageSize1" :page-size="pageSize1"
                        :current-page.sync="currentPage1" @current-change="handleCurrentChange1"></el-pagination>
                </div>
            </div> -->
        <!-- <el-dialog :visible.sync="dialogVisibleDetail" width="70%">
            <div class=" flex justify_center align_center ">
                <el-form :inline="true" :model="detailInline" label-width="110px" style="width: 70%;">
                    <el-form-item label="项目编号">
                        <el-input v-model="detailInline.projectID" placeholder="申请仓库" class="width_180" readonly>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="机器编号">
                        <el-input v-model="detailInline.machineID" placeholder="申请部门" class="width_180" readonly>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="测点编号">
                        <el-input v-model="detailInline.sensorID" placeholder="总价" class="width_180" readonly>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="报警状态数值">
                        <el-input v-model="detailInline.alarmStatus" placeholder="备注区域" class="width_180" readonly>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="测点名称">
                        <el-input v-model="detailInline.sensor" placeholder="备注区域" class="width_180" readonly>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="报警状态字符串中文">
                        <el-input v-model="detailInline.alarmRank" placeholder="备注区域" class="width_180" readonly>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="报警处理状态">
                        <el-input v-model="detailInline.gmtProc" placeholder="备注区域" class="width_180" readonly>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="处理时间">
                        <el-input v-model="detailInline.gmtProc" placeholder="备注区域" class="width_180" readonly>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="处理结果">
                        <el-input v-model="detailInline.procResult" placeholder="备注区域" class="width_180" readonly>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="客户报警处理相关信息">
                        <el-input v-model="detailInline.procDetail" placeholder="备注区域" class="width_180" readonly>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="处理详情">
                        <el-input v-model="detailInline.procDetailImgs" placeholder="备注区域" class="width_180" readonly>
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class=" flex justify_center align_center">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" class="mg_top_20" height="320"
                    style="width: 55%;" :default-sort="{prop: 'date', order: 'descending'}">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="featureFactor" label="特征指标" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="alarmRank" label="报警状态字符串中文（显示直接使用该值即可）" width="250"></el-table-column>
                    <el-table-column prop="currentValue" label="当前值" width=""></el-table-column>
                    <el-table-column prop="refValue" label="阈值" width=""></el-table-column>
                    <el-table-column prop="scale" label="超出比率" width=""></el-table-column>
                    <el-table-column prop="weightProportion" label="权重"></el-table-column>
                    <el-table-column prop="wiki" label="提醒建议"></el-table-column>
                </el-table>
            </div>
            <div class="flex justify_center pdb_20 mg_top_20">

                <el-button type="primary" @click="dialogVisibleDetail=false">确 定</el-button>
            </div>
        </el-dialog> -->
    </div>
    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        alarmDataQueryLimit,
        returnJumpURL
        // alarmDataQuery
    } from '@/api/api_deviceManagement.js'
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                tableData: [],
                multipleSelection: [],
                //页码
                currentPage: 1,
                currentPage1: 1,
                pageSize: 12,
                pageSize1: 5,
                pageQuantity: 0,
                pageQuantity1: 0,
                listData: [],
                input: '',
                timeValue: [],
                mIDValue: '',
                dialogVisibleDetail: false,
                detailInline: {},
                rowData: {},
                linkURL:'',
                machineLearning:'',
                procResult:'',
                alarmStatus:''
            }
        },
        filters:{
            istatus(type){
                const value = {
                    '1':'已处理',
                    '2':'未处理',
                }
                return value[type]
            }
        },
        created() {

        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
                this.machineLearning = userInfo.machineLearning;
                console.log(userInfo);
            }
            
            this.alarmDataQueryLimit();
            this.returnJumpURL();
        },

        methods: {
            //查询按钮
            query() {
                this.alarmDataQueryLimit()
            },
            async alarmDataQueryLimit() {

                var param = {
                    // startGmtCreate: this.timeValue[0],
                    // endGmtCreate: this.timeValue[1],
                    // projectID
                    // machineID:this.mIDValue,
                    // alarmStatus,
                    // alarmRank
                    // ID
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                    // userID
                }
                if(this.timeValue!=null){
                    param.startGmtCreate =this.timeValue[0]
                }
                if(this.timeValue!=null){
                    param.endGmtCreate =this.timeValue[1]
                }
                if (this.mIDValue != '') {
                    param.machineID = this.mIDValue
                }
                
                const selectRes = await alarmDataQueryLimit(param);
                console.log("---------",selectRes.rows)
                selectRes.rows.forEach(element => {
                //     if(element.alarmStatus==1){
                //     console.log("已处理");
                // }
                // if(element.alarmStatus==0){
                //     console.log("未处理");
                // }
                // if(element.procResult==1){
                //     console.log("已处理");
                // }
                // if(element.procResult==2){
                //     console.log("未处理");
                // }
                });
                this.listData = selectRes.rows;
                this.pageQuantity = selectRes.pageQuantity;
            },
            // async alarmDataQuery() {
            //     var param = {
            //         ID: this.rowData.ID
            //     }
            //     const selectRes = await alarmDataQuery(param)
            //     this.detailInline = selectRes.rows[0]
            //     var str = selectRes.rows[0].algorithmFactorDtos
            //     this.tableData = JSON.parse(str).array
            //     console.log("table", this.tableData);
            // },
            async returnJumpURL(){
                const res = await returnJumpURL();
                this.linkURL= res.URL
            },
            linkTo(){
                var link = this.linkURL
                window.open(link)
            },
            linkMaintain(){
                window.open('http://47.104.99.213:8080/Thingworx/Mashups/TW.Analytics.ConsoleMashup?appKey=23d7ee74-563a-4407-ba7d-24449064040e&x-thingworx-session=true')
            },
            check(rows) {
                console.log(rows);
                // this.dialogVisibleDetail = true;
                localStorage.setItem('alarmParam',JSON.stringify(rows))
                
                 this.$router.push({
                    // query: {
                    //     id: id
                    // },
                    path: '/deviceManagement/vibrationAnalysisAlarmAndDiagnosis/alarm/detailAlarm',
                    // query:{setid:111222}
                    // params:{setid:111222}//地址栏中看不到参数的内容
                })
            },

            

            handleSelectionChange(val) {
                this.multipleSelection = val;
                console.log("select", this.multipleSelection[0])
            },
            handleChange(id) {
                this.tabIndex = id;

            },
            //导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '振动分析报警表')
            },
             //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //    // path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
             //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },
            //日期格式
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
            //处理结果
            formatResult(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                let ret = '';
                if (data == 0) {
                    ret = '未处理'
                };
                if(data == 1){
                    ret = '已处理'
                }
                return ret
            },
            //布尔值格式
            formatBoolean(row, column, cellValue) {
                var ret = '' //你想在页面展示的值
                if (cellValue) {
                    ret = "是" //根据自己的需求设定
                } else {
                    ret = "否"
                }
                return ret;
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.alarmDataQueryLimit(); //查询table数据的方法
            },
            handleCurrentChange1(val) {
                this.currentPage1 = val;
                this.sparePartPickDetailQueryLimit(); //查询table数据的方法
            },
            linkTO() {

            }
        }
    }
</script>

<style scoped>
    .tab_box {
        width: 176px;
        height: 50px;
        line-height: 50px;
        color: #1A1A1A;

    }

    .tab_box_active {
        color: #009BFD;
        border-top: 3px solid #009BFD;
        background-color: #FFFFFF;
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
    }

    .spanWidth {
        width: 80px;
    }

    .divMargin {
        margin-right: 20px;
    }

    .buttonBorder {
        border: none;
    }
</style>
<style>
    /* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

    /* .el-button:focus,
    .el-button:hover {
    } */
</style>